*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  max-width: 100%;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Lato", sans-serif;
  color: #fff;
}

body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #000;
}

#background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  z-index: -1;
  user-select: none;
}

#background > div {
  position: absolute;
  letter-spacing: -1px;
  color: rgb(77, 77, 77);
  background-color: black;
  mix-blend-mode: multiply;
  font-weight: 600;
  font-family: "Lucida Console", Monaco, monospace;
  height: 100%;
  overflow: hidden;
}

#background > canvas {
  width: 100%;
  height: 100%;
  z-index: -2;
}

h1 {
  font-size: 2.5rem;
  margin: 10px 0 20px;
}

p {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 30px;
}

h2 {
  font-size: 1.8rem;
  margin: 10px 0;
}
